.login-wrapper {
  /* background-color: blue; */
  width: 500px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.message-wrapper {
  width: 80%;
  height: 15%;
}

@media only screen and (max-width: 700px) {
  .login-wrapper {
    /* background-color: blue; */
    width: 380px;

    margin: -150px 0 0 -190px;
  }
}
