.done {
  background-color: lightblue;
}
.active {
  background-color: rgb(255, 238, 0);
}
.canceled {
  background-color: lightcoral;
}
.new {
  background-color: lightgreen;
}
.ant-table-tbody > tr > td {
  padding: 6px;
  min-width: 150px;
  font-weight: 600;
}
.ant-table-thead > tr > th {
  padding: 6px;
  min-width: 150px;
  text-align: center;
  font-weight: 600;
}

.ant-table table {
  border-spacing: 6px 6px;
  font-weight: 600;
}

.ant-picker-input > Input {
  font-weight: 600;
}

.ant-picker-input:disabled {
  color: black;
}

.ant-select-selection-item {
  font-weight: 600;
}

.hidden{
  display: none;
}

textarea{
  background-color: aqua;
}
